import OneSignal from 'react-onesignal';

export default async function runOneSignal() {
  console.log('runOneSignal');
  await OneSignal.init({
    appId: 'b13d2907-9369-419d-be77-7905aa104850',
    allowLocalhostAsSecureOrigin: true,
  });
  OneSignal.Slidedown.promptPush();
  console.log(OneSignal.User.PushSubscription.id);
}
